import React from "react";
import { logo } from "../assets/image";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import logo1 from "../assets/image/99.pdf-removebg.png";
const Logo = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <Link to={""}>
      <div className="flex items-center gap-1 logo">
        <div className=" h-[35px] md:h-[45px]">
          <img
            src={logo}
            alt="newsky"
            className="w-full h-full object-contain"
          />
        </div>

        <div className=" h-[35px] md:h-[45px]">
          <img
            src={logo1}
            alt="newsky"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </Link>
  );
};

export default Logo;

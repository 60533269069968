import React from "react";
import loading from "../assets/loading.png";
import { createPortal } from "react-dom";
const Spinner = () => {
  return createPortal(
    <div className="fixed w-full h-screen z-[99999] top-0 bg-white left-0 flex items-center justify-center">
      <img
        src={loading}
        alt="loading"
        className="w-[180px] h-[180px] object-cover spinner"
      />
    </div>,
    document.body
  );
};

export default Spinner;

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { urlFor } from "../client";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import Mada from "../assets/NicePng_rupay-card-png_3654057.png";
import visa from "../assets/visa-logo-png-2028.png";
import logo from "../assets/download.jpg";
import { getScoial } from "../services/apiOffers";
const Footer = () => {
  const { t, i18n } = useTranslation();
  const [scoial, setScoial] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { scoial } = await getScoial();
        setScoial(scoial);
      } catch (err) {}
    };
    getData();
  }, []);

  return (
    <footer className="border border-gray-200">
      <div className="mx-auto w-full max-w-screen-xl lx:px-0 px-5 py-5">
        <div className="md:flex gap-6 items-center justify-between">
          <div className="mb-6 md:mb-0 w-fit">
            <Logo />
            <Payment />
          </div>
          <div className="mb-5 md:mb-0">
            <h2 className="text-[#ffd11bcf] border-gray-200   py-1 font-semibold text-2xl md:text-3xl text-start">
              <Trans i18nKey={"footer.why"} />
            </h2>
            <p className="text-lg">
              <Trans i18nKey={"footer.whydec"} />
            </p>
          </div>
          <div className="mb-5 md:mb-0">
            <h2 className="text-[#ffd11bcf] border-gray-200  py-1 font-semibold text-2xl md:text-3xl text-start">
              <Trans i18nKey={"footer.contact"} />
            </h2>
            <p className="text-lg">
              <Trans i18nKey={"footer.contactdec"} />
            </p>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between gap-4">
          {scoial.length > 0 && (
            <div className="flex mt-5 justify-between md:justify-start md:mb-0 gap-5 sm:mt-0">
              {scoial?.map((data) => (
                <a
                  key={data._id}
                  href={data.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                >
                  <img
                    className="w-8 h-8"
                    src={urlFor(data.imgUrl)}
                    alt={data?.imgUrl?.alt}
                    loading="lazy"
                  />
                </a>
              ))}
            </div>
          )}

          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 sm:pt-5 flex items-center gap-1 mt-5 md:mt-0">
            <span>
              <Trans i18nKey={"footer.copy.rigth"} />
            </span>
            <Link to={""} className="hover:underline">
              <Trans i18nKey={"footer.copy.left"} />
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
const Payment = () => {
  return (
    <div className="mt-3 flex items-center gap-2">
      <img src={Mada} alt="Mada" className="h-[20px] object-cover" />
      <img src={visa} alt="visa" className=" w-[80px] object-cover" />
      <img src={logo} alt="" className=" w-[50px] object-cover" />
    </div>
  );
};

import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./ui/AppLayout";
import { useTranslation } from "react-i18next";
const Home = lazy(() => import("./pages/Home"));
const Offers = lazy(() => import("./pages/Offers"));
const OfferDetails = lazy(() => import("./pages/OfferDetails"));
const Destination = lazy(() => import("./pages/Destination"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Article = lazy(() => import("./pages/Article"));
const ArticleDetails = lazy(() => import("./pages/ArticleDetails"));
const ArticleCatogray = lazy(() => import("./pages/ArticleCatogray"));

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.head.title =
      i18n.language === "ar"
        ? "NewSky وكاله سماء جديده للسفر والسياحة|مكة المكرمة|السعودية"
        : "NewSky Agency for Travel and Tourism|makkah|Saudi Arabia";
  }, [i18n.language]);

  return (
    <>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/offer/:offerId" element={<OfferDetails />} />
          <Route path="/destination/:destinationId" element={<Destination />} />
          <Route path="/article" element={<Article />} />
          <Route path="/article/:articleId" element={<ArticleDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/article/catogray/:catograyId"
            element={<ArticleCatogray />}
          />
          <Route path="*" element={<h2>404</h2>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import React from "react";
import { NavLink } from "react-router-dom";

const NavList = ({ item, onShowNav }) => {
  return (
    <NavLink
      to={item.route}
      className={({ isActive, isPending }) =>
        `${
          isActive
            ? "bg-[#1a56db] text-white rounded-md"
            : "text-[#197e90] font-semibold border-b"
        } px-3 py-2  w-full `
      }
      aria-current="page"
      onClick={() => onShowNav(false)}
    >
      {item.name}
    </NavLink>
  );
};

export default NavList;

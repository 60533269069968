import client from "../client";

export const getAllOffers = async (lan) => {
  try {
    const queryBanner = `*[_type == "banner"] {
      _id, 
      imgUrl__${lan}, 
      offer-> {
        _id,
        slug
      }, 
      title {
        ${lan}
      }
    }`;
    const queryOffer = `*[_type == "offers"] { poster__${lan}, _id , title {${lan}} ,overview {${lan}}, duration  ,startPrice ,slug ,map {${lan}}}`;
    const queryPlaces = `*[_type == "places"] { title {${lan}} ,image__banner , slug,_id}`;
    const resultBanner = await client.fetch(queryBanner);
    const resultOffer = await client.fetch(queryOffer);
    const resultPlaces = await client.fetch(queryPlaces);

    return { resultBanner, resultOffer, resultPlaces };
  } catch (error) {
    return error.message;
  }
};

export const getOffersLength = async (id) => {
  const offerParms = { _ref: id };
  const numberOffer = `*[_type == "offers" && place._ref == $_ref] {_id}`;
  const numberOfferq = await client.fetch(numberOffer, offerParms);
  const offerLength = numberOfferq?.length;
  return { offerLength };
};

export const getOfferById = async (offerId, lan = "ar") => {
  try {
    const query = `*[_type == "offers" && slug.current == $offerId] {
      inclusions[] {
        ${lan},
        _key,
        _type
      },
      exclusions[] {
        ${lan},
        _key,
        _type
      },
      title { ${lan} } ,
      duration { ${lan} },
      map { ${lan} },
      overview { ${lan} },
      typejourney { ${lan} },
      price[] { ${lan} },
      startPrice,
      singel,
      _createdAt,
      _id,
      _rev,
      _type,
      _updatedAt,
      images,
      itinerary[]{
        items[]{
          ${lan}
        },
        subTitle{
          ${lan}
        }
      }

    }
    `;
    const params = { offerId, lan };
    const result = await client.fetch(query, params);
    return result;
  } catch (error) {
    return error.message;
  }
};
export const getDestinationById = async (placeId, lan = "ar") => {
  try {
    const params = { placeId };
    const queryDestination = `*[_type == "places" && slug.current == $placeId] { title {${lan}} ,image__banner , _id ,image__banner { asset, title {${lan}}}}`;
    const resultDestination = await client.fetch(queryDestination, params);
    const offerParms = { _ref: resultDestination[0]?._id };
    const queryOffer = `*[_type == "offers" && place._ref == $_ref] { poster__${lan}, _id , title {${lan}} ,overview {${lan}}, slug, duration ,startPrice ,map {${lan}} }`;
    const resultOffer = await client.fetch(queryOffer, offerParms);

    return { resultOffer, resultDestination };
  } catch (error) {
    return error.message;
  }
};

export const getAboutData = async (lan) => {
  let language = lan;
  const params = { language };
  try {
    const queryOffice = '*[_type == "office" && language == $language]';
    const queryWhyUs = '*[_type == "why_us" && language == $language]';
    const queryWhoUs = '*[_type == "who_us" && language == $language]';
    const office = await client.fetch(queryOffice, params);
    const whyUs = await client.fetch(queryWhyUs, params);
    const whoUs = await client.fetch(queryWhoUs, params);
    return { office, whoUs, whyUs };
  } catch (error) {
    return error.message;
  }
};

export const getScoial = async () => {
  try {
    const query = `*[_type == "scoial"]`;
    const scoial = await client.fetch(query);
    return { scoial };
  } catch (error) {
    return error.messag;
  }
};

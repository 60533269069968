import React from "react";
import { NavLink } from "react-router-dom";

const MainNav = ({ item }) => {
  return (
    <li>
      <NavLink
        to={item.route}
        className={({ isActive, isPending }) =>
          `${
            isActive
              ? "bg-[#1a56db] text-white rounded-md"
              : "text-[#197e90] font-semibold"
          } px-3 py-2  `
        }
        aria-current="page"
      >
        {item.name}
      </NavLink>
    </li>
  );
};

export default MainNav;
